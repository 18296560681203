/* Projects Section */
.projects-section {
  padding: 5rem 0;
  background-color: var(--background-light);
  color: var(--text-dark);
}

.dark .projects-section {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
}

/* Project Cards */
.project-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  padding: 1.5rem;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.project-image-container {
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.project-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-image-container:hover img {
  transform: scale(1.05);
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
}

.tag {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0.8rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  color: var(--text-dark);
  transition: background-color 0.2s ease;
}

.dark .tag {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-light);
}

.tag img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: var(--heading-light);
}

.dark .project-title {
  color: var(--heading-dark);
}

.project-description {
  flex-grow: 1;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-2px);
}

.dark .btn-primary {
  background-color: var(--primary-color-dark);
}

.dark .btn-primary:hover {
  background-color: var(--primary-color-hover-dark);
}

/* Modal Styles */
.project-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1.5rem;
  overflow-y: auto;
  backdrop-filter: blur(4px);
}

.project-modal {
  width: 100%;
  max-width: 900px;
  z-index: 1001;
  margin: 2rem auto;
  /* Ne pas définir de style de fond ou couleur ici, on les met dans modal-content */
}

.modal-content {
  background: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 15px;
  position: relative;
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: var(--text-dark);
  overflow-x: hidden;
  /* Ajout de scrollbar stylisée */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.modal-content::-webkit-scrollbar {
  width: 6px;
}

.modal-content::-webkit-scrollbar-track {
  background: transparent;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.dark .modal-content {
  background: rgba(30, 30, 30, 0.95);
  color: var(--text-light);
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.dark .modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

.modal-title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: var(--heading-light);
  padding-right: 2rem; /* Espace pour le bouton de fermeture */
  line-height: 1.2;
}

.dark .modal-title {
  color: var(--heading-dark);
}

.modal-description {
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
  white-space: pre-line; /* Permet de respecter les sauts de ligne du texte */
}

/* Carousel dans le modal */
.modal-carousel {
  margin-bottom: 2rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* Styles pour Slick Slider */
.slick-slider {
  margin-bottom: 0;
}

.slick-slide {
  outline: none;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: white;
  opacity: 0.7;
  font-size: 8px;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

.slick-prev, .slick-next {
  z-index: 10;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: 15px;
}

.slick-next {
  right: 15px;
}

.slick-prev:before, .slick-next:before {
  font-size: 30px;
  opacity: 0.7;
}

.slick-prev:hover:before, .slick-next:hover:before {
  opacity: 1;
}

/* Date et liens */
.modal-date {
  font-size: 0.95rem;
  color: var(--text-secondary-light);
  margin-bottom: 1.5rem;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.dark .modal-date {
  color: var(--text-secondary-dark);
  background-color: rgba(255, 255, 255, 0.05);
}

.modal-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-link {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-link:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-2px);
}

.dark .modal-link {
  background-color: var(--primary-color-dark);
}

.dark .modal-link:hover {
  background-color: var(--primary-color-hover-dark);
}

.modal-link svg {
  margin-left: 0.5rem;
}

/* Bouton fermeture */
.btn-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: background-color 0.2s ease, transform 0.2s ease;
  z-index: 10;
  color: var(--text-dark);
}

.dark .btn-close {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-light);
}

.btn-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transform: rotate(90deg);
}

.dark .btn-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Responsive */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }

  .modal-content {
    padding: 1.5rem;
    max-height: 90vh;
  }

  .modal-title {
    font-size: 1.75rem;
  }

  .modal-links {
    flex-direction: column;
    gap: 0.75rem;
  }

  .modal-link {
    width: 100%;
    justify-content: center;
  }
}