.skills-section {
  background-color: var(--background-light);
  color: var(--text-dark);
  padding: 5rem 0;
}

.dark .skills-section {
  background-color: var(--background-dark);
  color: var(--text-light);
}

.section-title {
  text-align: left;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.tabs-container {
  margin-bottom: 2rem;
}

.tabs {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  flex-wrap: wrap;
}

.tab-item {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
  position: relative;
  overflow: hidden;
}

.tab-item:hover,
.tab-item.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.tab-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.tab-text {
  white-space: nowrap;
}

/* Animation pour le changement d'onglets */
.tab-content {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Styles des cartes de compétences */
.skill-card {
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 2rem; /* Espace pour la barre de progression */
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.dark .skill-card {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.dark .skill-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.icon {
  width: 4rem;
  height: 4rem;
  transition: transform 0.3s ease;
}

.skill-card:hover .icon {
  transform: scale(1.1);
}

/* Barre de progression */
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: linear-gradient(90deg, #4F46E5, #818CF8);
  transition: width 0.8s ease-in-out;
}

/* Affichage du niveau au survol */
.skill-level {
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.2s ease;
}

.level-text {
  background-color: var(--primary-color);
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  font-weight: bold;
}

/* Selector for mobile */
.mobile-selector select {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1rem;
}

/* Media queries */
@media (max-width: 768px) {
  .tabs {
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }

  .tab-item {
    padding: 0.75rem 1rem;
    white-space: nowrap;
  }

  .section-title {
    text-align: center;
  }
}